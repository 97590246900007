import Repository from "../Services/Repository.js";

export default {
	state: {
		postList: [],
		postDetail: [],
		newPostLabels: null,
		interestList: [],
		interestDetail: null,
		newPost: {},
		notifications: null,
		alreadyExistingDrafts: [],
		sellerError: null,
		tryAgainMessage: null
	},
	getters: {
		notificationCount(state) {
			return state.notifications;
		},
		notificationsLoaded(getters) {
			return !isNaN(getters.notificationCount);
		},
		postListEmpty(state) {
			return state.postList.length === 0;
		},
		tryAgain(state) {
			return state.tryAgainMessage && state.tryAgainMessage;
		}
	},
	mutations: {
		SET_POST_DETAIL(state, post) {
			state.postDetail = post;
		},
		SET_POSTS(state, postList) {
			state.postList = postList;
		},
		SET_NOTIFICATION_COUNT(state, count) {
			state.notifications = count;
		},
		SET_SELLER_ERROR(state, e) {
			state.sellerError = e;
		},
		SET_POST_LABELS(state, labels) {
			state.newPostLabels = labels;
		},
		APPEND_TO_POST_LIST(state, posts) {
			state.postList.push(posts);
		},
		RESET_POST_LIST(state) {
			state.postList = [];
		},
		SET_INTEREST_LIST(state, interest) {
			state.interestList = interest;
		},
		SET_INTEREST_DETAIL(state, interestDetail) {
			state.interestDetail = interestDetail;
		},
		SET_NEW_POST(state, form) {
			state.newPost = form;
		},
		SET_PREEXISTING_DRAFTS(state, drafts) {
			state.alreadyExistingDrafts = drafts;
		},
		SET_TRY_AGAIN(state, setOverClear) {
			if (setOverClear) state.tryAgainMessage = "Please try again";
			else state.tryAgainMessage = null;
		},
	},
	actions: {
		resetPostList: async function (context) {
			context.commit('RESET_POST_LIST');
		},
		async retrieveUsersPostsByStatus(context, status) {
			const token = localStorage.getItem("access_token");
			return new Promise((resolve, reject) => {
				Repository.get('seller/posts?status=' + status, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							context.commit("SET_POSTS", response.data);
							resolve(response);
						})
					.catch(e => {
						reject(e)
					});
			})
		},
		async retrieveArchivedPosts(context) {
			const token = localStorage.getItem("access_token");
			return new Promise((resolve, reject) => {
				context.commit('RESET_POST_LIST');

				var siteStatus;
				for (siteStatus = 2; siteStatus <= 4; siteStatus++) {
					Repository.get('seller/posts?status=' + siteStatus, {
						headers: { Authorization: `Bearer ${token}` }
					})
						.then(
							response => {
								context.commit("APPEND_TO_POST_LIST", response.data);
							})
						.catch(e => {
							reject(e)
						});
				};
				resolve(true);
			})
		},
		retrieveInterestList(context) {
			const token = localStorage.getItem("access_token");

			Repository.get('seller/expressed-interest-list', {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_INTEREST_LIST", response.data);
					});
		},
		retrieveInterestByID(context, id) {
			const token = localStorage.getItem("access_token");

			Repository.get('seller/expressed-interest?id=' + id, {
				headers: { Authorization: `Bearer ${token}` }
			})
				.then(
					response => {
						context.commit("SET_INTEREST_DETAIL", response.data);
					});
		},
		continueToPostNow(context, form) {
			context.commit('SET_NEW_POST', form);
		},
		async checkIfDraftsExist(context) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.get('seller/draft-already-exists-notifiation', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(response => {
						context.commit("SET_PREEXISTING_DRAFTS", response.data);
						resolve(response.data);
					})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})

		},
		createDraft: async function (context, form) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('seller/draft',
					{
						BusinessStatusId: form.businessStatusId,
						TradingStatusId: form.tradingStatusId,
						TurnoverId: form.turnoverId,
						EmployeesId: form.employeesId,
						PaymentMethodId: 1,
						Reference: form.reference,
						PostingTypeId: 1, //Posting Type ID is a deprecated but required Field
						ActivityHeading: form.activityHeading,
						PrimaryCategory: form.primaryCategory,
						Description: form.description,
						DisplayHome: form.displayHome,
						Assets: form.assets,
						AdditionalCategories: form.additionalCategories,
						Location: form.locations
					},
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		postDraft: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('seller/post-draft?id=' + payload.id,
					{
						Startdate: payload.dates[0],
						Enddate: payload.dates[1],
						useCustomCompanyName: payload.useCustomCompanyName,
						Company: payload.company
					},
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		/* Deprecated
		modifyPostDates: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-dates?id=' + payload.id, { StartDate: payload.dates[0], EndDate: payload.dates[1] }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		*/
		extendPost: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/extend-post?id=' + payload.id, { Enddate: payload.newEndDate }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		withdrawPost: async function (context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/withdraw-post?id=' + id, '', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		exportExpressionsOfInterest: async function (context, id) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/export-expressions-of-interest?id=' + id, '', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		repostPost: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('seller/repost?id=' + payload.id,
					{
						StartDate: payload.dates[0],
						EndDate: payload.dates[1],
						useCustomCompanyName: payload.useCustomCompanyName,
						Company: payload.company
					},
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		emailPost: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.post('seller/email-post?id=' + payload.id, {
					EmailTos: payload.sendList,
					Message: payload.Message
				},
					{
						headers: { Authorization: `Bearer ${token}` }
					})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},

		//Amend Post Endpoints
		amendPostDetail: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-detail?id=' + payload.id, {
					Reference: payload.form.reference,
					ActivityHeading: payload.form.activityHeading,
					Locations: payload.form.locations,
					BusinessStatusID: payload.form.businessStatusID,
					TradingStatusID: payload.form.tradingStatusID,
					TurnoverID: payload.form.turnoverID,
					EmployeesID: payload.form.employeeID,
					DisplayHome: payload.form.displayHome
				}, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		amendPostDescription: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-description?id=' + payload.id, { Description: payload.description }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		amendPostCategory: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-category?id=' + payload.id, {
					PrimaryCategory: payload.category
				}, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		amendPostAdditionalCategories: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-additional-categories?id=' + payload.id,
					{ Categories: payload.categories }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		amendPostAssets: async function (context, payload) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.patch('seller/post-assets?id=' + payload.id, { Assets: payload.assets }, {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},


		async retrieveNotificationCount(context) {
			const token = localStorage.getItem("access_token");

			return new Promise((resolve, reject) => {
				Repository.get('seller/notifications', {
					headers: { Authorization: `Bearer ${token}` }
				})
					.then(
						response => {
							context.commit("SET_NOTIFICATION_COUNT", response.data);
							resolve(response.data);
						})
					.catch(e => {
						context.commit("SET_SELLER_ERROR", e);
						reject(e.response.data.response);
					});
			})
		},
		setTryAgainMessage(context) {
			context.commit('SET_TRY_AGAIN', true);
		},
		clearTryAgainMessage(context) {
			context.commit('SET_TRY_AGAIN', false);
		}

	}
};
