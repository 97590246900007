<template>
	<div class="container">
		<h2>Register as an Insolvency Practitioner</h2>
		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step :complete="step > 1" step="1" editable
					>Personal Details</v-stepper-step
				>

				<v-stepper-step
					:complete="step > 2"
					step="2"
					:editable="
						!$v.formData.stepOne.$invalid 
							&& emailsValidated"
					>Address Details</v-stepper-step
				>

				<v-stepper-step
					:complete="step > 3"
					step="3"
					:editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid && 
							emailsValidated
					"
					>Registration Confirmation</v-stepper-step
				>

				<v-stepper-step
					step="4"
					:editable="
						!$v.formData.stepOne.$invalid &&
							!$v.formData.stepTwo.$invalid &&
							!$v.formData.stepThree.$invalid &&
							emailsValidated
					"
					>Password</v-stepper-step
				>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content step="1">
					<v-row class="px-2 mx-2 d-flex d-sm-none">
						<v-spacer></v-spacer>
						<v-btn
							fab
							small
							color="secondary"
							@click="validateStep"
							class="ma-1"
							><v-icon>mdi-chevron-right</v-icon></v-btn
						>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-select
								:items="labels.registerLabels.titleLabels"
								item-text="description"
								item-value="titleId"
								v-model="formData.stepOne.titleID"
								:error-messages="titleErrors"
								label="Title"
								required
								clearable
							></v-select>
							<v-text-field
								label="Forenames"
								v-model="formData.stepOne.forenames"
								:error-messages="forenameErrors"
								@input="$v.formData.stepOne.forenames.$touch()"
								@blur="$v.formData.stepOne.forenames.$touch()"
								required
							></v-text-field>
							<v-text-field
								label="Surname"
								v-model="formData.stepOne.surname"
								:error-messages="nameErrors"
								@input="$v.formData.stepOne.surname.$touch()"
								@blur="$v.formData.stepOne.surname.$touch()"
								required
							></v-text-field>
							<v-text-field
								v-model="formData.stepOne.position"
								:error-messages="positionErrors"
								label="Position"
								@input="$v.formData.stepOne.position.$touch()"
								@blur="$v.formData.stepOne.position.$touch()"
								required
							></v-text-field>
							<v-text-field
								v-model="formData.stepOne.company"
								:error-messages="companyErrors"
								label="Company Name"
								@input="$v.formData.stepOne.company.$touch()"
								@blur="$v.formData.stepOne.company.$touch()"
								required
							></v-text-field>
							<v-text-field
								v-model="formData.stepOne.telephone"
								:error-messages="telephoneErrors"
								label="Telephone"
								@input="$v.formData.stepOne.telephone.$touch()"
								@blur="$v.formData.stepOne.telephone.$touch()"
								required
							></v-text-field>
							<v-text-field
								v-model="formData.stepOne.mobile"
								label="Mobile"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								label="Email"
								v-model="formData.stepOne.email"
								:error-messages="emailErrors"
								@input="$v.formData.stepOne.email.$touch()"
								@blur="$v.formData.stepOne.email.$touch()"
								required
							></v-text-field>
							<v-text-field
								label="Confirm Email"
								v-model="formData.stepOne.emailConfirm"
								ref="emailConfirm"
								:error-messages="confirmEmailErrors"
								@input="DisableCopyPasteOnEmail"
								@blur="DisableCopyPasteOnEmail"
								required
							></v-text-field>
							<v-text-field
								label="Secondary Email"
								v-model="formData.stepOne.secondaryEmail"
								:error-messages="secondEmailErrors"
								@input="$v.formData.stepOne.secondaryEmail.$touch()"
								@blur="$v.formData.stepOne.secondaryEmail.$touch()"
								required
							></v-text-field>
							<v-text-field
								label="Confirm Secondary Email"
								v-model="formData.stepOne.secondaryEmailConfirm"
								ref="secondaryEmailConfirm"
								:error-messages="confirmSecondEmailErrors"
								@input="DisableCopyPasteOnSecondEmail"
								@blur="DisableCopyPasteOnSecondEmail"
								required
							></v-text-field>
							<v-icon v-if="emailValidationMessage !== null">mdi-alert</v-icon><p style="display: initial;">{{ emailValidationMessage }}</p>
						</v-col>
					</v-row>
					<v-row class="px-2 mx-2">
						<v-spacer></v-spacer>
						<v-btn
							fab
							small
							color="secondary"
							@click="validateStepOne"
							class="ma-1"
							:loading="loading"
							><v-icon>mdi-chevron-right</v-icon></v-btn
						>
					</v-row>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-row class="px-2 mx-2 d-flex d-sm-none">
						<v-btn
							fab
							small
							color="secondary"
							@click="step--"
							class="ma-1"
							><v-icon>mdi-chevron-left</v-icon></v-btn
						>
						<v-spacer></v-spacer>

						<v-btn
							fab
							small
							color="secondary"
							@click="validateStep"
							class="ma-1"
							><v-icon>mdi-chevron-right</v-icon></v-btn
						>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="formData.stepTwo.addressLine1"
								label="Address Line 1"
								:error-messages="addressLine1Errors"
								@input="
									$v.formData.stepTwo.addressLine1.$touch()
								"
								@blur="
									$v.formData.stepTwo.addressLine1.$touch()
								"
								required
							></v-text-field>
							<v-text-field
								v-model="formData.stepTwo.addressLine2"
								label="Address Line 2"
							></v-text-field>
							<v-text-field
								v-model="formData.stepTwo.addressLine3"
								label="Address Line 3"
							></v-text-field>
							<v-text-field
								v-model="formData.stepTwo.townCity"
								:error-messages="townCityErrors"
								@input="$v.formData.stepTwo.townCity.$touch()"
								@blur="$v.formData.stepTwo.townCity.$touch()"
								label="Town/City"
								required
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="formData.stepTwo.county"
								label="County"
							></v-text-field>
							<v-text-field
								v-model="formData.stepTwo.postcode"
								label="Postcode"
								@input="$v.formData.stepTwo.postcode.$touch()"
								@blur="$v.formData.stepTwo.postcode.$touch()"
								:error-messages="postcodeErrors"
								required
							></v-text-field>
							<v-select
								:items="labels.registerLabels.countryLabels"
								item-text="name"
								item-value="code"
								@input="$v.formData.stepTwo.country.$touch()"
								@blur="$v.formData.stepTwo.country.$touch()"
								v-model="formData.stepTwo.country"
								:error-messages="countryErrors"
								label="Country"
								required
								clearable
							></v-select>
						</v-col>
					</v-row>
					<v-row class="px-2 mx-2">
						<v-btn
							fab
							small
							color="secondary"
							@click="step--"
							class="ma-1"
							><v-icon>mdi-chevron-left</v-icon></v-btn
						>
						<v-spacer></v-spacer>

						<v-btn
							fab
							small
							color="secondary"
							@click="validateStep"
							class="ma-1"
							><v-icon>mdi-chevron-right</v-icon></v-btn
						>
					</v-row>
				</v-stepper-content>

				<v-stepper-content step="3">
					<p style="color: grey; fontSize: 0.85em">
						To act as a seller on this site you must be either a
						licensed Insolvency Practitioner registered with one of
						the UK regulatory professional bodies or acting on their
						behalf. If you are acting on behalf of a licensed
						Insolvency Practitioner (as, say, a PA to your
						employer), please include that person’s details here and
						ensure that you have obtained permission to register
						with IP-BID.com. Please provide the information below:
					</p>
					<v-row>
						<v-col cols="12" md="6">
							<v-select
								:items="labels.registerLabels.capacityLabels"
								item-text="description"
								item-value="capacityId"
								v-model="formData.stepThree.capacityID"
								label="Capacity"
								:error-messages="capacityErrors"
								required
								clearable
							></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="formData.stepThree.IPName"
								label="Insolvency Practitioner's Name"
								v-if="
									formData.stepThree.capacityID &&
										labels.registerLabels.capacityLabels.filter(
											l =>
												l.capacityId ===
												formData.stepThree.capacityID
										)[0].code === '2'
								"
								:error-messages="ipNameErrors"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row class="px-2 mx-2">
						<v-btn
							fab
							small
							color="secondary"
							@click="step--"
							class="ma-1"
							><v-icon>mdi-chevron-left</v-icon></v-btn
						>
						<v-spacer></v-spacer>

						<v-btn
							fab
							small
							color="secondary"
							@click="validateStep"
							class="ma-1"
							><v-icon>mdi-chevron-right</v-icon></v-btn
						>
					</v-row>
				</v-stepper-content>

				<v-stepper-content step="4">
					<v-row>
						<v-col cols="12" md="6" class="py-1">
							<v-text-field
								v-model="formData.stepFour.password"
								label="Password"
								:error-messages="passwordErrors"
								password
								@input="$v.formData.stepFour.password.$touch()"
								@blur="
									$v.formData.stepFour.confirmPassword.$touch()
								"
								:type="showPass ? 'text' : 'password'"
								:append-icon="
									showPass ? 'mdi-eye' : 'mdi-eye-off'
								"
								@click:append="showPass = !showPass"
								required
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6" class="py-1">
							<v-text-field
								v-model="formData.stepFour.confirmPassword"
								label="Confirm Password"
								:error-messages="confirmPasswordErrors"
								password
								:type="showConfirmPass ? 'text' : 'password'"
								:append-icon="
									showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'
								"
								@click:append="
									showConfirmPass = !showConfirmPass
								"
								required
							></v-text-field>
						</v-col>
						<p
							style="color: grey; fontSize: 0.85em; padding-left: 12px"
						>
							By registering, you agree with the
							<router-link :to="{ name: 'TCs' }"
								>terms and conditions</router-link
							>, and
							<router-link :to="{ name: 'PrivacyPolicy' }"
								>privacy policy</router-link
							>.
						</p>
					</v-row>
					<v-row class="px-2 mx-2">
						<v-btn
							fab
							small
							color="secondary"
							@click="step--"
							class="ma-1"
							><v-icon>mdi-chevron-left</v-icon></v-btn
						>
						<v-spacer></v-spacer>

						<v-btn
							tile
							color="secondary"
							@click.prevent="submit"
							:loading="loading"
							>submit</v-btn
						>
					</v-row>
					<p v-if="errorMessage !== null">{{ errorMessage }}</p>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
	required,
	requiredIf,
	email,
	minLength,
	maxLength,
	sameAs
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

//Form Validation Helpers
const createRequiredErrors = (field, fieldString) => {
	const errors = [];
	if (!field.$dirty) return errors;
	!field.required && errors.push(fieldString + " is required");
	return errors;
};

const containsDigit = value => /\d/.test(value);
const containsUppercase = value => /[A-Z]/.test(value);
const containsLowercase = value => /[a-z]/.test(value);

export default {
	mixins: [validationMixin],
	metaInfo() {
		return {
			title: "IP-BID.com - Insolvency Practitioner Registration"
		};
	},
	name: "RegisterSeller",
	data() {
		return {
			//Meta
			showPass: false,
			showConfirmPass: false,
			previousEmailConfirmValue: null,
			previousSecondaryEmailConfirmValue: null,
			emailValidated: null,
			secondaryEmailValidated: null,
			step: 1,
			steps: 4,
			successMessage: null,
			errorMessage: null,
			emailValidationMessage: null,
			loading: false,
			TCAgree: false,
			formData: {
				stepOne: {
					titleID: null,
					forenames: null,
					surname: null,
					email: null,
					emailConfirm: null,
					secondaryEmail: null,
					secondaryEmailConfirm: null,
					company: null,
					position: null,
					telephone: null,
					mobile: null
				},
				stepTwo: {
					addressLine1: null,
					addressLine2: null,
					addressLine3: null,
					county: null,
					townCity: null,
					country: null,
					postcode: null
				},
				stepThree: {
					capacityID: null,
					IPName: null
				},
				stepFour: {
					password: null,
					confirmPassword: null
				}
			}
		};
	},
	created() {
		//False payload argument specifies to retrieve seller focussed labels
		this.$store.dispatch("retrieveRegisterLabels", false);
	},
	validations: {
		formData: {
			stepOne: {
				titleID: { required },
				surname: { required },
				forenames: { required },
				email: { email, required },
				emailConfirm: { required, sameAsEmail: sameAs("email") },
				secondaryEmail: { email },
				secondaryEmailConfirm: { sameAsEmail: sameAs("secondaryEmail") },
				company: { required },
				position: { required },
				telephone: { required }
			},
			stepTwo: {
				postcode: { required, maxLength: maxLength(10) },
				townCity: { required },
				country: { required },
				addressLine1: { required }
			},
			stepThree: {
				capacityID: { required },
				IPName: {
					required: requiredIf(function() {
						return (
							this.formData.stepThree.capacityID &&
							this.labels.registerLabels.capacityLabels.filter(
								l =>
									l.capacityId ===
									this.formData.stepThree.capacityID
							)[0].code === "2"
						);
					})
				}
			},
			stepFour: {
				password: {
					required,
					minLength: minLength(7),
					containsDigit,
					containsLowercase,
					containsUppercase
				},
				confirmPassword: {
					required,
					minLength: minLength(7),
					containsDigit,
					containsLowercase,
					containsUppercase,
					sameAsPassword: sameAs("password")
				}
			}
		}
	},
	methods: {
		async submit() {
			this.errorMessage = null;

			if (!this.$v.$invalid) {
				this.response = null;
				this.errorMessage = null;
				this.loading = true;

				//Secondary Email is validated as an email in the backend but is not required - therefore must either be an email or null, not an empty string
				if (this.formData.stepOne.secondaryEmail === "") {
					this.formData.stepOne.secondaryEmail = null;
				}

				await this.$store
					.dispatch("registerSeller", this.formData)
					.then(response => {
						response;
						this.$router.push({
							name: "RegistrationSuccess"
						});
					})
					.catch(e => {
						this.errorMessage = e.response.data.response;
					});
				this.loading = false;
			} else {
				this.$v.formData.$touch();
			}
		},
		async validateStepOne() {
			this.emailValidationMessage = null;
			if (!this.$v.formData.stepOne.$invalid) {
				this.loading = true;

				if (this.formData.stepOne.secondaryEmail === "") {
					this.formData.stepOne.secondaryEmail = null;
					this.formData.stepOne.secondaryEmailConfirm = null;
				}

				if (this.formData.stepOne.email == this.emailValidated &&
					(this.formData.stepOne.secondaryEmail == null || this.formData.stepOne.secondaryEmail == this.secondaryEmailValidated)) {
					this.emailValidationMessage = null;
					this.step++;
					this.loading = false;
					return;
				}

				this.emailValidationMessage = "Validating email(s)...";

				await this.$store
					.dispatch("validateEmails", this.formData)
					.then(() => {
						this.emailValidated = this.formData.stepOne.email;
						this.secondaryEmailValidated = this.formData.stepOne.secondaryEmail;
						this.loading = false;
						this.emailValidationMessage = null;
						this.step++;
					})
					.catch(e => {
						if (e.response.data.response != undefined) {
							this.emailValidationMessage = e.response.data.response;
						} else {
							var messages = [];

							if (e.response.data.response1.verdict == 2) {
								messages.push("Please enter a valid Email.");
							}

							if (e.response.data.response2.verdict != undefined && e.response.data.response2.verdict == 2) {
								messages.push("Please enter a valid Secondary Email.");
							}

							this.emailValidationMessage = messages.join(" ") + " To register with this email please contact support@ip-bid.com.";
						}
						
						this.$v.formData.$touch();
					});
				this.loading = false;
			} else {
				this.$v.formData.$touch();
			}
		},
		validateStep() {
			var step = parseInt(this.step);
			const evalStep = section => {
				if (!section.$invalid) {
					this.step++;
				} else {
					section.$touch();
				}
			};
			switch (step) {
				case 1:
					evalStep(this.$v.formData.stepOne);
					break;
				case 2:
					evalStep(this.$v.formData.stepTwo);
					break;
				case 3:
					evalStep(this.$v.formData.stepThree);
					break;
			}
		},
		DisableCopyPasteOnEmail() {
			if (this.previousEmailConfirmValue == null) {
				if (this.formData.stepOne.emailConfirm.length == 1) {
					this.previousEmailConfirmValue = this.formData.stepOne.emailConfirm;
				} else {
					this.formData.stepOne.emailConfirm = this.previousEmailConfirmValue;
					this.$refs.emailConfirm.lazyValue = this.formData.stepOne.emailConfirm;
				}
			}
			else if (this.formData.stepOne.emailConfirm.length == 0 ||
				this.formData.stepOne.emailConfirm.length == this.previousEmailConfirmValue.length ||
				this.formData.stepOne.emailConfirm.length == this.previousEmailConfirmValue.length + 1 ||
				this.formData.stepOne.emailConfirm.length == this.previousEmailConfirmValue.length - 1) {
					this.previousEmailConfirmValue = this.formData.stepOne.emailConfirm;
			} else {
				this.formData.stepOne.emailConfirm = this.previousEmailConfirmValue;
				this.$refs.emailConfirm.lazyValue = this.formData.stepOne.emailConfirm;
			}

			this.$v.formData.stepOne.emailConfirm.$touch()
		},
		DisableCopyPasteOnSecondEmail() {
			if (this.previousSecondaryEmailConfirmValue == null) {
				if (this.formData.stepOne.secondaryEmailConfirm.length == 1) {
					this.previousSecondaryEmailConfirmValue = this.formData.stepOne.secondaryEmailConfirm;
				} else {
					this.formData.stepOne.secondaryEmailConfirm = this.previousSecondaryEmailConfirmValue;
					this.$refs.secondaryEmailConfirm.lazyValue = this.formData.stepOne.secondaryEmailConfirm;
				}
			}
			else if (this.formData.stepOne.secondaryEmailConfirm.length == 0 ||
				this.formData.stepOne.secondaryEmailConfirm.length == this.previousSecondaryEmailConfirmValue.length ||
				this.formData.stepOne.secondaryEmailConfirm.length == this.previousSecondaryEmailConfirmValue.length + 1 ||
				this.formData.stepOne.secondaryEmailConfirm.length == this.previousSecondaryEmailConfirmValue.length - 1) {
					this.previousSecondaryEmailConfirmValue = this.formData.stepOne.secondaryEmailConfirm;
			} else {
				this.formData.stepOne.secondaryEmailConfirm = this.previousSecondaryEmailConfirmValue;
				this.$refs.secondaryEmailConfirm.lazyValue = this.formData.stepOne.secondaryEmailConfirm;
			}

			this.$v.formData.stepOne.secondaryEmailConfirm.$touch()
		},
	},
	computed: {
		...mapState(["labels"]),

		emailsValidated() {
			return this.formData.stepOne.email == this.emailValidated &&
				(this.formData.stepOne.secondaryEmail == "" || this.formData.stepOne.secondaryEmail == null || this.formData.stepOne.secondaryEmail == this.secondaryEmailValidated);
		},

		//Step One Errors
		titleErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.titleID,
				"Title"
			);
		},
		nameErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.surname,
				"Surname"
			);
		},
		forenameErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.forenames,
				"Forename"
			);
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.formData.stepOne.email.$dirty) return errors;
			!this.$v.formData.stepOne.email.required &&
				errors.push("Please Enter an Email");
			!this.$v.formData.stepOne.email.email &&
				errors.push("Email should be valid");
			return errors;
		},
		confirmEmailErrors() {
			const errors = [];
			if (!this.$v.formData.stepOne.emailConfirm.$dirty) return errors;
			!this.$v.formData.stepOne.emailConfirm.required &&
				errors.push("Please Confirm Email");
			!this.$v.formData.stepOne.emailConfirm.sameAsEmail &&
				errors.push("Email must match");
			return errors;
		},
		secondEmailErrors() {
			const errors = [];
			if (!this.$v.formData.stepOne.secondaryEmail.$dirty) return errors;
			!this.$v.formData.stepOne.secondaryEmail.email &&
				errors.push("Second Email should be valid");
			return errors;
		},
		confirmSecondEmailErrors() {
			const errors = [];
			if (!this.$v.formData.stepOne.secondaryEmailConfirm.$dirty) return errors;
			!this.$v.formData.stepOne.secondaryEmailConfirm.sameAsEmail &&
				errors.push("Second Email must match");
			return errors;
		},
		positionErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.position,
				"Position"
			);
		},
		companyErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.company,
				"Company Name"
			);
		},
		telephoneErrors() {
			return createRequiredErrors(
				this.$v.formData.stepOne.telephone,
				"Telephone"
			);
		},

		//Step Two Errors
		addressLine1Errors() {
			return createRequiredErrors(
				this.$v.formData.stepTwo.addressLine1,
				"Address Line 1"
			);
		},
		townCityErrors() {
			return createRequiredErrors(
				this.$v.formData.stepTwo.townCity,
				"Town/City"
			);
		},
		postcodeErrors() {
			const errors = [];
			if (!this.$v.formData.stepTwo.postcode.$dirty) return errors;
			!this.$v.formData.stepTwo.postcode.required &&
				errors.push("Postcode is required");

			!this.$v.formData.stepTwo.postcode.maxLength &&
				errors.push("Postcode cannot be longer than 10 characters");

			return errors;
		},
		countryErrors() {
			return createRequiredErrors(
				this.$v.formData.stepTwo.country,
				"Country"
			);
		},

		//Step Three Errors
		capacityErrors() {
			return createRequiredErrors(
				this.$v.formData.stepThree.capacityID,
				"Capacity"
			);
		},
		ipNameErrors() {
			return createRequiredErrors(
				this.$v.formData.stepThree.IPName,
				"Insolvency Practitioner's Name"
			);
		},
		//Step Four Errors
		passwordErrors() {
			const errors = [];
			if (!this.$v.formData.stepFour.password.$dirty) return errors;
			!this.$v.formData.stepFour.password.required &&
				errors.push("Please Enter a Password");
			!this.$v.formData.stepFour.password.containsDigit &&
				errors.push("Password Must Contain a Digit");
			!this.$v.formData.stepFour.password.containsUppercase &&
				errors.push("Password Must Contain an Uppercase Character");
			!this.$v.formData.stepFour.password.containsLowercase &&
				errors.push("Password Must Contain an Lowercase Character");
			!this.$v.formData.stepFour.password.minLength &&
				errors.push("Password must contain at least 7 characters");
			return errors;
		},
		confirmPasswordErrors() {
			const errors = [];
			if (!this.$v.formData.stepFour.confirmPassword.$dirty)
				return errors;
			!this.$v.formData.stepFour.confirmPassword.required &&
				errors.push("Please Confirm Password");
			!this.$v.formData.stepFour.confirmPassword.sameAsPassword &&
				errors.push("Passwords must match");
			return errors;
		}
	}
};
</script>

<style lang="sass" scoped>
//Reduce spacing between fields 
.v-input
	padding: 4px 0 4px 0
	margin: 4px 0 4px 0
</style>